.homepage-title {
  width: 100%;
  display: flex;
  position: relative;
  color: white;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}

.homepage-title h1 {
  font-size: 25px;
}

.zoom-options {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 87px;
  height: 100%;
  min-height: 40px;
}

.zoom-buttons button {
  width: 100px;
}

.zoom-button {
  position: relative;
  width: 30px;
  height: 30px;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  color: white;
  font-size: 27px;
  line-height: 28px;
  font-weight: 500;
  user-select: none;
  margin: 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  background: rgb(102, 102, 102);
  background-clip: padding-box;
  box-shadow: 0px 4px 18px -4px rgba(119, 121, 137, 0.3);
}

.zoom-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3); 
}

.zoom-button::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(#691CDF, #B441FF);
  content: '';
  z-index: -1;
  border-radius: 5px;
}

@media (max-height: 500px) and (max-width: 1000px) {
  .homepage-title {
    display: none;
  }
}