* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  overflow: hidden;
  touch-action: none;
  font-family: "Poppins", Verdana, sans-serif;
  background-color: #020202;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left, center, bottom right;
  background-image: radial-gradient(
      ellipse at top left,
      #0d4e4c 0%,
      rgba(132, 249, 214, 0) 50%
    ),
    radial-gradient(
      ellipse at top center,
      #230c44 0%,
      rgba(132, 249, 214, 0) 35%
    ),
    radial-gradient(
      ellipse at bottom right,
      #0d4846 0%,
      rgba(132, 249, 214, 0) 50%
    );
}

#root {
  height: inherit;
}

.App {
  height: inherit;
}

button, input {
  font-family: "Poppins", Verdana, sans-serif;
}

.hidden {
  visibility: hidden;
}

.flex-end {
  align-self: flex-end;
}

.currency {
  background: linear-gradient(90deg, #9262db 0%, #c477f7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.bottom-drawer {
  display: none;
  margin-bottom: 12px;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .bottom-drawer {
    display: none !important;
  }
}
@media (min-height: 890px) and (min-width: 1000px) {
  .bottom-drawer {
    display: flex;
    width: 60px;
  }
}
@media (max-width: 1200px) {
  .bottom-drawer {
    display: flex;
    width: 60px;
    height: 2px;
  }
}