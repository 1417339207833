.history-slider-container {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 22px;
  margin-bottom: 42px;
  height: fit-content;
}

.history-slider {
  -webkit-appearance: none;
  position: relative;
  border-radius: 2px;
  width: 100%;
  height: 10px;
  background-color: transparent;
}

.history-slider:hover {
  cursor: grabbing;
}

.history-slider:focus {
  outline: none;
}

.history-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 10px;
}

.history-slider::-webkit-slider-thumb {
  position: relative;
  bottom: 5px;
  -webkit-appearance: none;
  background-color: black;
  border: 1px solid #B441FF;
  width: 19px;
  height: 19px;
  border-radius: 19px;
}

.date-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  color: white;
  background: rgba(2, 2, 2, 0.3);
  border-radius: 5px;
  padding: 2px 4px;
  width: 62px;
  height: 22px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  user-select: none;
}

.date-tooltip:after {
  content: '';
  position: absolute;
  top: -50%;
  left: 50%;
  margin-top: 1px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(2, 2, 2, 0.3) transparent;
}

.history-slider-axis-lines {
  position: absolute;
  user-select: none;
}

.history-slider-axis-line {
  position: absolute;
  top: -17px;
  z-index: 1;
}