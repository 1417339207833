.loader {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 45%; 
  left: 0;
  right: 0;
  text-align: center;
  display: inline-block;
  transform: translateZ(1px);
}

.loader > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: loading-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes loading-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(900deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(1800deg);
  }
}