.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 220ms ease-in-out;
  animation-name: alert-open;
  animation-duration: 220ms;
}
.modalOpen {
  z-index: 2;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(10px);
}
.alert-backdrop {
  background: #ffffff;
  box-shadow: 0px 4px 18px -4px rgba(119, 121, 137, 0.3);
  border-radius: 15px;
  width: 350px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms ease-in-out;
}
.modal,
.modal-body,
.alert-body,
.alert-body h2,
.modal-footer,
.modal-header,
.alert-header,
.modal-footer div {
  background: transparent;
}
.modal {
  font-family: "Poppins";
  color: #020202;
  border-radius: 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.modal-body h2 {
  font: 500 23px "Poppins";
  line-height: 38px;
  color: #020202;
}
.modal-body p,
.modal-body a {
  font: 400 16px "Poppins";
  line-height: 27px;
}
.modal-body p {
  color: #020202;
}
.modal-footer button {
  background: #b441ff;
  border-radius: 40px;
  width: 300px;
  height: 45px;
}
.alert {
  flex: 1;
  position: relative;
  border: none;
  border-radius: 20px;
  background: #ffffff;
  padding: 1.5rem;
  user-select: none;
  transition: all 250ms ease-in-out;
  overflow: hidden;
}
.alert-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
@media screen and (max-width: 400px) {
  .alert-backdrop {
    width: 300px;
  }
  .modal-footer button {
    width: 250px;
  }
}