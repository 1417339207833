.nft-map-container {
  overflow: hidden;
  scrollbar-width: none;
  touch-action: manipulation;
  display: flex;
  flex-direction: column;
  background-color: black;
  flex: 1;
}

.nft-map {
  position: relative;
  display: flex;
  user-select: none;
  transform-origin: 0 0;
}

.nft-map-image {
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  display: grid;
  cursor: help;
  z-index: -1;
}

.nft-map-image--loaded {
  opacity: 1;
}

.single-map {
  grid-template-columns: 100%;
}

.nft-map-image--loaded {
  opacity: 1;
}

.single-map {
  grid-template-columns: 100%;
}

.nft-map-image .image-part {
  width: 100%;
}

.nft-list {
  position: absolute;
  width: 100%;
  height: 100%;
}

.nft {
  cursor: pointer;
  position: absolute;
  display: flex;
  transform: scale(1);
  justify-content: center;
  align-items: flex-end;
  background-size: 100% 100%;
}

.nft:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.4);
}

.nft--empty {
  cursor: help;
}

.nft--mintable-plot {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft--mintable-plot:hover {
  cursor: default;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
}

.blurred-row {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
}