.manage-body {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: -webkit-fill-available;
  height: -moz-available;
  height: stretch;
  overflow: auto;
}

.manage-nfts-container {
  display: grid;
  width: 65%;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  grid-template-columns: repeat(auto-fit, 265px);
  grid-auto-rows: max-content;
  gap: 40px 0;
  overflow: auto;
}

.nft-card {
  width: 225px;
  height: 322px;
  background-color: #020202;
  border-radius: 20px;
  font-size: 14px;
  color: #ABA1B0;
  padding: 12px;
  justify-self: center;
  user-select: none;
}

.nft-image-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.no-image {
  background-color: #D9D9D9;
}

.nft-image {
  width: 100%;
  height: auto;
  border-radius: inherit;
}

.nft-info>div {
  display: flex;
  color: #ABA1B0;
  justify-content: space-between;
}

.nft-info>div>span {
  color: white;
}

.nft-price {
  background: linear-gradient(90deg, #691CDF 0%, #B441FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.manage-sidebar {
  position: relative;
  min-width: 360px;
  background-color: rgba(2, 2, 2, 0.3);
  border-radius: 40px;
  padding: 38px 23px;
  overflow: auto;
  margin: 0 12px 24px 24px;
}

.manage-sidebar>h1 {
  font-size: 18px;
  color: white;
  text-align: left;
}

.manage-sidebar>h2 {
  font-size: 14px;
  color: #ABA1B0;
  text-align: left;
}

.manage-sidebar-info {
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  justify-content: space-around;
}

.nft-info-price {
  margin-top: 30px;
}

.manage-sidebar-info .nft-info {
  width: 220px;
}

.manage-sidebar-info-image {
  position: relative;
  width: 125px;
  height: 125px;
  margin-right: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.manage-nft-image {
  width: 100%;
  border-radius: inherit;
}

.manage-nft-image-edit {
  position: absolute;
  top: 16px;
  right: 8px;
  width: 25px;
  height: 25px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-nft-image-edit:hover {
  background: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
}

.manage-sidebar-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-sidebar-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.manage-metadata {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.manage-sale-options {
  display: flex;
  justify-content: space-between;
}

.metadata-field-container {
  display: flex;
  justify-content: center;
}

.metadata-field {
  background-color: transparent;
  color: white;
  font-size: 14px;
  width: 100%;
  height: 37px;
  padding: 0 26px;
  border: 1px solid #ABA1B0;
  border-radius: 8px;
  outline: none;
}

.metadata-field:focus {
  background: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
  border: 1px solid white;
}

.empty-nft-list {
  display: flex;
  gap: 36px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  user-select: none;
}

.empty-nft-list>h2 {
  font-size: 25px;
}

.empty-nft-list>p {
  font-size: 14px;
}

.empty-nft-list>button {
  font-size: 16px;
}

.manage-sale-fee {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
  padding-top: 2px;
  padding-right: 6px;
}

.manage-sale-fee .seller-price {
  font-size: 12px;
}

.manage-sale-fee .percentage-fee {
  font-size: 11px;
}

.metadata-price {
  position: relative;
}

.metadata-price-currency {
  position: absolute;
  top: 6px;
  right: 20px;
  color: white;
}

@media only screen and (max-width: 650px) {
  .manage-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .manage-nfts-container {
    width: 100%;
  }

  .manage-sidebar {
    position: fixed;
    overflow: auto;
    max-height: 100%;
    min-width: 320px;
    bottom: 0;
    width: 100%;
    border-radius: 40px 40px 0 0;
    margin: 0;
    background: rgba(2, 2, 2, 0.8);
    backdrop-filter: blur(4px);
  }
}
