.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.how-it-works p {
  font-size: 18px;
  max-width: 1000px;
}
.third-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.columns {
  padding: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1000px;
}
.column {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.column h1 {
  font-size: 21px;
}
.column p {
  font-size: 14px;
}
.column img {
  height: 200px;
  width: 200px;
}
.second {
  align-items: flex-start;
}
.container {
  padding-bottom: 120px;
  display: flex;
  align-items: center;
}

.circle {
  width: 59px;
  height: 59px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid;
  border-color: #691cdf;
  color: white;
  font-weight: 600;
  font-size: 25px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
}

.column .active {
  background: linear-gradient(180deg, #b441ff 0%, #691cdf 100%);
}

.vertical-line {
  width: 1px;
  height: 150px;
  background-color: rgba(171, 161, 176, 0.25);
}
.first-container {
  padding: 0px;
}
.second-container {
  padding: 20px 40px;
}
.additional-information {
  height: 229px;
  padding-bottom: 40px;
  background: #020202;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: center;
}
.additional-information p {
  max-width: 1000px;
}
.mobile {
  display: none;
}
.desktop {
  display: flex;
}
@media screen and (max-width: 900px) {
  .container {
    padding-bottom: 0px;
  }
  .second-container {
    padding: 20px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .how-it-works p {
    padding: 0px 0px 40px 0px;
    font-size: 16px;
  }
  .columns {
    padding: 0px;
  }
  .mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-wrapper img {
    height: 180px;
    width: 180px;
  }
  .mobile-wrapper h1 {
    font-size: 17px;
  }
  .mobile-wrapper p {
    line-height: 27px;
    padding: 0px;
  }
  .vertical-line {
    height: 280px;
  }
  .additional-information {
    height: 300px;
    padding: 20px;
  }
  .mobile-wrapper .active {
    background: linear-gradient(180deg, #b441ff 0%, #691cdf 100%);
  }
}
