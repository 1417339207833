.header {
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}
.header .logo {
}
.header .logo img {
  height: 12px;
}
.header img {
  cursor: pointer;
  height: 19px;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  padding: 10px;
}
.body .links {
  background: transparent;
  align-items: flex-start;
}
.body a {
  padding: 7px;
  font-size: 14px;
}
.body a:hover,
.body .active {
  line-height: normal;
}
.body p {
  padding: 10px 0px 10px 17px;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
}
.body span {
  font-size: 11px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 37px 0px;
  border-bottom: 1px solid rgba(171, 161, 176, 0.15);
}
.footer button {
  font-size: 14px;
  padding: 3px;
  height: 40px;
  width: 157px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.overlay-open {
  opacity: 1;
  z-index: 0;
  visibility: visible;
}

.overlay-hidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.drawer {
  left: 0;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  background-color: rgba(2, 2, 2, 1);
  width: 50%;
}

.hidden {
  visibility: hidden;
}

.animate {
  width: 30%;
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}
@media screen and (max-width: 600px) {
  .animate {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .footer button {
    width: 140px;
  }
}
