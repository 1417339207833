.textual-page {
  overflow: auto;
  color: #ffffff;
  padding: 20px 50px;
  text-align: left;
  margin-bottom: 50px;
}
.textual-page h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 60px;
}
.textual-page h3 {
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
}
.textual-page h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.textual-page li {
  margin-left: 40px;
  line-height: 30px;
}


.textual-page a {
  color: grey;
}

.textual-page p {
  padding: 10px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
.hiw-page p {
  padding: 20px 0px;
}
.footer-logo {
  margin: 10px 0px 30px 0px;
}
.about-us-page {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-page h1 {
  margin: 0px 0px 0px 160px;
}
.about-us-text {
  margin: 0px 160px 0px 160px;
}
.our-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 160px 0px 160px;
}
.cards {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}
.team-card {
  margin: 70px 0px;
  width: 352px;
  min-height: 530px;
  padding: 15px;
  background: #020202;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.team-card h1 {
  font-size: 21px;
}
.team-card p {
  font-size: 14px;
}
.textual-page .position {
  padding: 0px 0px 15px 0px;
  line-height: 10px;
}
.team-card-picture {
  width: 129px;
  height: 129px;
  position: absolute;
  bottom: 470px;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.team-card-picture img {
  object-fit: cover;
  height: 125px;
  border-radius: 50%;
}
.social-media-page {
  background: rgba(244, 244, 244, 0.14);
  border-radius: 5px;
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-media-page img {
  object-fit: cover;
}
.faq-title {
  color: white;
  margin: 30px 0;
}
.faq-row {
  color: white;
  text-align: left;
  margin: 12px 67px;
  border-bottom: 1px solid #aba1b0;
}
.faq-question {
  font-size: 21px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.faq-answer {
  font-size: 16px;
  padding-bottom: 15px;
  color: rgba(255, 255, 255, 0.8);
}
.faq-body {
  overflow: auto;
  padding-bottom: 60px;
}
.about-us-paragraph {
  text-align: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .about-us-page {
    display: flex;
    flex-direction: column;
  }
  .about-us-page h1 {
    margin: 0px;
  }
  .about-us-text {
    margin: 0px;
  }
  .cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .our-team {
    margin: 40px 0px 0px 0px;
  }
}
@media screen and (max-width: 900px) {
  .textual-page {
    padding: 20px 20px;
    margin-bottom: 50px;
  }
  .textual-page h1 {
    font-size: 28px;
    text-align: center;
  }
  .textual-page p {
    padding: 10px 0px;
    font-size: 14px;
  }
  .footer-logo {
    margin: 20px 0px;
  }
}
@media screen and (max-width: 600px) {
  .team-card {
    width: 300px;
    min-height: 560px;
  }
  .team-card-picture {
    width: 100px;
    height: 100px;
    bottom: 510px;
  }
  .team-card-picture img {
    height: 96px;
  }
}
