.button-predefined-style {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 160px;
  height: 37px;
  color: white;
  font: 500 16px "Poppins";
  background: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
  border-color: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
  border-radius: 25px;
  border: 1px transparent;
  cursor: pointer;
  font-size: 15px;
}
@media screen and (max-width: 400px) {
  .button-predefined-style {
    width: 250px;
  }
}

.button-outlined-style {
  height: 37px;
  border: 1px solid #B441FF;
  background-color: transparent;
  border-radius: 25px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 0 16px;
}

.button-filled-style {
  height: 37px;
  background-color: #B441FF;
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 0 16px;
}

.button-predefined-style:hover, .button-filled-style:hover, .button-outlined-style:hover {
  background: linear-gradient(90deg, #b441ff 0%, #691cdf 100%);
  border-color: linear-gradient(90deg, #b441ff 0%, #691cdf 100%);
}

.button-plain-style {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: white;
}

.full-width {
  width: 100%;
}