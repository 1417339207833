.mint-header {
  color: white;
  text-align: center;
  user-select: none;
}

.mint-header h1 {
  font-size: 25px;
}

.mint-header h3 {
  font-size: 14px;
}

.address-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

.address-input {
  background-color: transparent;
  color: white;
  width: 367px;
  padding: 0 26px;
  border: 1px solid #ABA1B0;
  border-radius: 8px;
  outline: none;
  height: 37px;
}

.address-error {
  border: 1px solid rgba(200, 0, 0, 0.9);
  color: rgba(255, 0, 0, 0.9);
}

.mint-button {
  width: 113px;
  margin-left: 24px;
}

.add-address {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: white;
}

.add-address span {
  padding-left: 5px;
  color: #aba1b0;
  font-size: 12px;
}

.add-address:hover {
  cursor: pointer;
}
.whitelist{
  color: red;
}
.regular-price{
  text-decoration: line-through;;
}
@media only screen and (max-width: 1200px) {
  .mint-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    background-color: rgba(2, 2, 2, 0.7);
    border-radius: 20px 20px 0px 0px;
    padding: 24px 12px;
  }

  .address-container {
    flex-direction: column;
    align-items: center;
  }

  .address-input {
    width: 90%;
    padding: 8px 16px;
  }

  .mint-button {
    margin-left: 0;
    margin-top: 12px;
  }
}
