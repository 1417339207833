.footer-container {
  width: 100%;
  display: flex;
  margin-top: auto;
  align-items: center;
  height: 54px;
  padding: 16px 88px;
  background-color: #020202;
}

.footer-links {
  display: flex;
  min-width: 460px;
  justify-content: space-between;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 32px;
}

.footer-links a:hover {
  border-bottom: 1px solid #b441ff;
}

.footer-links .active {
  border-bottom: 1px solid #b441ff;
}

@media screen and (max-width: 900px) {
  .footer-container {
    display: none;
  }
}
