.wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0 20px;
  background: rgba(244, 244, 244, 0.14);
  border-radius: 70px 70px 0px 0px;
  overflow: hidden;
  text-align: center;
  height: calc(100% - 20px);
}

@media only screen and (max-width: 700px) {
  .wrapper {
    margin: 0px;
  }
}