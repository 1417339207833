.buy-page {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.buy-page .nft-map-container {
  width: 1200px;
}
.buy-page .image-parts {
  width: 100%;
  height: 100%;
}
.buy-page .address-container-buy {
  height: 50px;
  margin: 10px 0px 0px 0px;
}
.buy-page .add-address {
  margin: 10px 0;
  height: 35px;
}
.buy-page .mint-header button {
  width: 100%;
}
.buy-page .address-input-buy {
  width: 100%;
  height: 35px;
  margin: 10px 0;
  padding: 0px 20px 0px 20px;
  background: transparent;
  outline: none;
  border: 1px solid #aba1b0;
  border-radius: 8px;
  color: white;
}
.right-section {
  display: flex;
  flex-direction: column;
  background: rgba(2, 2, 2, 0.3);
  border-radius: 40px;
  margin: 0px 40px 0px 15px;
  width: 380px;
  color: white;
  padding: 30px 30px;
  text-align: center;
  height: 75%;
}
.right-section p {
  font-size: 14px;
  font-weight: 500;
}
.right-section h1 {
  padding: 20px 0px;
  font-size: 25px;
  width: 320px;
}
.no-nft-selected {
  height: 130px;
}
.no-buy-page {
  padding-top: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 85%;
}
.no-buy-page p {
  color: black;
  margin: 40px 0px;
  font-size: 22px;
}
.no-buy-page .nft-map-container {
  filter: blur(3px);
  height: 100%;
}
.nft-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
}
.nft-info {
  display: flex;
  flex-direction: column;
}
.info-title {
  color: #aba1b0;
  text-align: left;
}
.info-title p {
  padding: 0px 0px 2px 0px;
}
.info-data {
  text-align: right;
  color: #ffffff;
}
.info-data p {
  padding: 0px 0px 2px 0px;
}
.buy-button {
  width: 200px;
}
.price {
  margin-top: 1rem;
}
.price-color {
  background: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.buy-nft-title {
  font-size: 20px;
  font-weight: 500;
  color: white;
  text-align: start;
  padding: 20px 0px 50px 0px;
  width: 100%;
}
.buy-disabled {
  background: linear-gradient(90deg, #df1c1c 0%, #ff4141 100%);
}
.buy-disabled:hover {
  cursor: not-allowed;
  background: linear-gradient(90deg, #df1c1c 0%, #ff4141 100%);
}
@media (min-height: 890px) and (min-width: 1000px) {
  .buy-page {
    height: auto;
    padding-top: 10px;
  }
  .buy-page .nft-map-container {
    height: 100%;
  }
  .right-section {
    height: auto;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .buy-page {
    padding-top: 10px;
  }
  .buy-page .nft-map {
    width: unset;
  }
  .buy-page .nft-map-image {
    min-width: unset;
  }
  .buy-nft-title {
    text-align: center;
  }
  .right-section {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    background-color: rgba(2, 2, 2, 0.7);
    border-radius: 20px 20px 0px 0px;
    padding: 24px 12px;
    margin: 0 0 12px 0;
    height: auto;
    align-items: center;
  }
  .right-section .mint-header {
    position: unset;
    padding: unset;
    background: transparent;
  }

  .buy-page .address-container-buy {
    flex-direction: column;
    align-items: center;
    height: 70px;
    width: 320px;
    display: flex;
    align-items: center;
  }

  .buy-button {
    margin-left: 0;
    margin-top: 12px;
  }
}
