.options-button-container {
  display: flex;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 14px;
  right: 14px;
}

.options-button {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 28px;
  line-height: 10px;
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.options-button:hover {
  color: white;
  background: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
  border-color: linear-gradient(90deg, #691cdf 0%, #b441ff 100%);
}

.popover-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 128;
}

.popover-button-list {
  position: absolute;
  top: 28px;
  right: 18px;
  min-width: 54px;
  max-width: 100px;
  background-color: white;
  border-radius: 4px 0 4px 4px;
  z-index: 130;
}

.popover-button {
  border: none;
  color: black;
  background-color: transparent;
  font-size: 10px;
  width: 100%;
  text-align: initial;
  padding-left: 9px;
  cursor: pointer;
}

.popover-danger {
  color: #EB5757;
}