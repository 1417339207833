.header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  background: transparent;
}

.logo {
}
.header-page .logo img {
  height: 17px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 30px;
  max-width: 413px;
  height: 37px;
  background: rgba(2, 2, 2, 0.3);
  border-radius: 40px;
}

.header-page a {
  text-decoration: none;
  font: 500 16px "Poppins";
  font-style: normal;
  line-height: 21px;
  color: #aba1b0;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  cursor: pointer;
  align-items: center;
  background: transparent;
}

.header-page a:hover {
  line-height: 32px;
  color: #ffffff;
  border-bottom-color: #b441ff;
}

.header-page .active {
  color: white;
  line-height: 32px;
  color: #ffffff;
  border-bottom-color: #b441ff;
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(2, 2, 2, 0.3);
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
}

.dropdown:hover {
  background: rgba(50, 50, 50, 0.4);
}

.header-page img {
  padding-right: 10px;
  height: 10px;
  background: transparent;
}

.header-page p {
  background: transparent;
  font-family: "Poppins";
}

.header-page span {
  color: #aba1b0;
  font: 500 14px "Poppins";
  background: transparent;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  width: 190px;
  background: rgba(2, 2, 2, 1);
  border-radius: 0px 0px 20px 20px;
  padding: 3px 15px 5px 15px;
  z-index: 1;
  text-align: center;
}

.dropdown-content:hover {
  background: rgba(150, 0, 0, 1);
}

.header-connect-button {
  color: white;
  background: transparent;
  font: 500 14px "Poppins";
  cursor: pointer;
  border: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover {
  border-bottom: 1px solid rgba(171, 161, 176, 0.25);
  border-radius: 20px 20px 0px 0px;
}

.header-page .modal-image {
  height: 150px;
  padding: 0;
}

.header-page .modal-link {
  margin-top: 2rem;
  color: #1070ff;
}

.header-page .modal-link:hover {
  line-height: 27px;
  color: #1070ff;
  border-bottom-color: transparent;
}

.desktop {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.mobile img {
  cursor: pointer;
  height: 19px;
}

.trading {
  position: absolute;
  left: 65px;
  text-align: 0;
  display: flex;
  align-items: center;
  top: 83px;
  color: #f5c561;
  font-size: 12px;
}

@media screen and (max-width: 899.9px) {
  .desktop {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .mobile {
    display: none;
  }
}