.footer-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 17px 0px 0px 0px;
}
.social-media {
  display: flex;
  padding-left: 17px;
  align-items: center;
  justify-content: space-between;
}
.social-media p {
  height: 28px;
  width: 28px;
  padding: 0px;
  background: rgba(244, 244, 244, 0.14);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
